
  import { Action } from "vuex-class";
  import { Component, Vue, Prop } from "vue-property-decorator";
  import { WorkStatus } from "@/store/modules/activity.store";
  import { bus } from "@/main";
  import { activityIsDossier } from "@/utils/model-helpers";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
    },
  })
  export default class DossierPartWorkStatusBar extends Vue {
    @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Activity;

    @Prop() currentStatus!: WorkStatus;

    @Prop() activity!: Activity;

    prospectStatuses = [WorkStatus.new, WorkStatus.contact, WorkStatus.estimate, WorkStatus.followUp, WorkStatus.followUpLT, WorkStatus.won, WorkStatus.lost];

    dossierStatuses = [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer, WorkStatus.sold, WorkStatus.done, WorkStatus.archived];

    loading = false;

    savingStatus: WorkStatus | null = null;

    bus = bus;

    get isDossier() {
      return activityIsDossier(this.activity);
    }

    get statuses() {
      if (this.isDossier) {
        return this.dossierStatuses;
      }

      return this.prospectStatuses;
    }

    async handleActivityStartup(status: WorkStatus) {
      this.loading = true;

      this.savingStatus = status;

      try {
        let patchPayload: ActivityPatchPayload = { id: this.activity.id, work_status: status };
        if ([WorkStatus.new, WorkStatus.contact, WorkStatus.estimate, WorkStatus.followUp, WorkStatus.followUpLT, WorkStatus.won].includes(status)) {
          patchPayload.status = "OPEN";
        }
        await this.patchActivity(patchPayload);

        this.$toast.open({ message: this.$t("views.dossier.work_status_updated") as string, type: "success", position: "bottom-right" });
      } catch (e: any) {
        if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "transition_exception") {
          bus.$emit("show-transition-failed", { status: e.errors[0].params, activityId: this.activity.id });
        }
        if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "validation.can_transition") {
          bus.$emit("show-transition-failed", { status: [e.errors[0].message], activityId: this.activity.id });
        }
        if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "validation.required_if") {
          bus.$emit("show-activity-lost-reason", this.activity.id);
        }
      }

      this.loading = false;

      this.savingStatus = null;
    }

    get isMobile() {
      // @ts-ignore
      return this.$root.isMobile;
    }
  }
